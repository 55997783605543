/* Bootstrap overrides */
$primary: #8d0000;

/* Bootstrap styles */
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../node_modules/bootstrap-icons/font/bootstrap-icons';

/* Additional styles */

// Take up full page height
html body #root {
  height: 100vh;
}

// Vertical page layout
#root {
  display: flex;
  flex-direction: column;
}

// Make <main> take up remaining height
main {
  flex-grow: 1;
}

// Fix scrollbar jumping
header, main, footer {
  padding-left: calc(100vw - 100%);
}

// Override bootstrap scrollbar jumping fix
body {
 padding-right: 0 !important;
}

// Custom class to enable scale on hover effect
.scale-on-hover {
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}

.scale-on-hover:hover {
  box-shadow: 1px 1px 0.5rem var(--bs-gray);
  transform: scale(1.03);
  z-index: 500;
}